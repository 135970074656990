// import { Injectable } from '@angular/core';
// import {
//   HttpRequest,
//   HttpHandler,
//   HttpErrorResponse,
//   HttpHeaders,
// } from '@angular/common/http';
// import { catchError, retry } from 'rxjs/operators';
// import { throwError } from 'rxjs';
// import { Router } from '@angular/router';
// @Injectable({
//   providedIn: 'root',
// })
// export class AuthHtppInterceptorServiceService {
//   public token;
//   public basicAuth;
//   private idleTimeout = 30 * 60 * 1000; // 30 minutes in milliseconds
//   private lastActivity: number;
//   private idleTimer: any;

//   constructor(private router: Router) {
//     this.token = 'Bearer ' + localStorage.getItem('1');
//     this.basicAuth =
//       `Basic ` +
//       btoa('shinelogics:DAF87DSFDSFDSA98FSADKJE324KJL32HFD7FDSFB24343J49DSF');
//       this.lastActivity = Date.now();
//       this.initializeIdleTimer();
//   }

//   handelError(error: HttpErrorResponse): any {
//     return throwError(error);
//   }

//   private _getToken(): any {
//     return localStorage.getItem('1');
//   }

//   private _setHeaders(authRequired = true): HttpHeaders {
//     this.token = 'Bearer ' + localStorage.getItem('1');
//     if (authRequired) {
//       if (this.token) {
//         const headersConfig = {
//           token: this.token,
//           Authorization: this.basicAuth,
//         };
//         return new HttpHeaders(headersConfig);
//       } else {
//         const token = this._getToken();
//         this.token = 'Bearer ' + token;
//         if (token != null) {
//           const headersConfigs = {
//             token: this.token,
//             Authorization: this.basicAuth,
//           };
//           return new HttpHeaders(headersConfigs);
//         }
//         const headersConfig = {
//           Authorization: this.basicAuth,
//         };
//         return new HttpHeaders(headersConfig);
//       }
//     } else {
//       const headersConfig = {
//         'Content-Type': 'application/json',
//         Authorization: this.basicAuth,
//       };
//       return new HttpHeaders(headersConfig);
//     }
//   }
//   private initializeIdleTimer(): void {
//     this.idleTimer = setInterval(() => {
//       const timeElapsed = Date.now() - this.lastActivity;
//       if (timeElapsed >= this.idleTimeout) {
//         this.logout();
//       }
//     }, 60000); // Check every minute
//   }

//   intercept(req: HttpRequest<any>, next: HttpHandler): any {
//     req = req.clone({
//       headers: this._setHeaders(),
//     });
//     return next.handle(req).pipe(
//       catchError((error: HttpErrorResponse) => {
//         if (error.status === 400) {
//           localStorage.clear();
//           return this.router.navigateByUrl('/auth/login');
//         } else {
//           return throwError(error);
//         }
//       })
//     );
//   }
// }
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpHeaders,
  HttpEvent,
} from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthHtppInterceptorServiceService {
  private token: string;
  private basicAuth: string;
  private idleTimeout = 2 * 60 * 60 * 1000; // 30 minutes in milliseconds
  private lastActivity: number;
  private idleTimer: any;

  constructor(private router: Router) {
    this.token = 'Bearer ' + localStorage.getItem('1');
    this.basicAuth =
      'Basic ' +
      btoa('shinelogics:DAF87DSFDSFDSA98FSADKJE324KJL32HFD7FDSFB24343J49DSF');
    this.lastActivity = Date.now();
    this.initializeIdleTimer();
  }

  private initializeIdleTimer(): void {
    this.idleTimer = setInterval(() => {
      const timeElapsed = Date.now() - this.lastActivity;
      if (timeElapsed >= this.idleTimeout) {
        this.logout();
      }
    }, 60000); // Check every minute
  }

  private resetIdleTimer(): void {
    this.lastActivity = Date.now();
  }

  private logout(): void {
    localStorage.clear();
    clearInterval(this.idleTimer);
    this.router.navigateByUrl('/auth/login');
  }

  private handleErrorResponse(error: HttpErrorResponse): Observable<never> {
    if (error.status === 400) {
      this.logout();
    }
    return throwError(error);
  }

  private getToken(): string {
    return localStorage.getItem('1');
  }

  private setHeaders(authRequired = true): HttpHeaders {
    this.token = 'Bearer ' + localStorage.getItem('1');
    const headersConfig = {
      Authorization: this.basicAuth,
    };
    if (authRequired && this.token) {
      headersConfig['token'] = this.token;
    }
    return new HttpHeaders(headersConfig);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const authReq = req.clone({
      headers: this.setHeaders(),
    });

    return next.handle(authReq).pipe(
      map((event: HttpEvent<any>) => {
        this.resetIdleTimer();
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleErrorResponse(error);
      })
    );
  }
}
